// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("./chosen.jquery.min.js")

import "bootstrap";

document.addEventListener("turbolinks:load", function(e) {
  // e.preventDefault();

  $("tr[data-link]").click(function() {
    window.location.href = $(this).data('link');
  });

  $("#config-form-content-type-select").change(function(e) {
    fetch(`/configs/content_fields?content_type_id=${e.target.value}&plate_site_id=${$(this).data("site-id")}`)
      .then(response => response.json())
      .then(function(data) {
        var content_fields = parseContentFields(data)
        $("#config-form-all-content-fields").val(JSON.stringify(content_fields))
        $("#config-form-selected-content-fields-select").empty()

        var new_options = []
        content_fields.forEach(function(item) {
          new_options.push(`<option value="${item.name}">${item.title} (${item.name})</option>`)
        })
        $("#config-form-selected-content-fields-select")[0].innerHTML = new_options.join();
        $("#config-form-selected-content-fields-select").trigger("chosen:updated")
      });
  })

  function parseContentFields(data) {
    return data.map(function(item) {
      return {
        id: item.id,
        name: item.attributes.name,
        title: item.attributes.title,
        field_type: item.attributes.field_type,
        selected: false
      }
    }).filter(function(item) {
      return item.field_type != "reference"
    })
  }

  $(".chosen-select").chosen()

})

// document.addEventListener("turbolinks:load", () => {
//     $('[data-toggle="tooltip"]').tooltip()
// })
